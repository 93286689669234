
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButtons, IonButton, menuController, modalController } from "@ionic/vue";
import { refresh, menu, addCircle } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import draggable from "vuedraggable";
import moment from "moment";

import { dateFormat } from "../services/utils";
import { openToast } from "../services/toast";

import apiLeads from "../services/leads";

import ModalNuovoLead from "../components/ModalNuovoLead.vue";
import LeadDetail from "../components/LeadDetail.vue";

export default {
  name: "Leads",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonIcon,
    IonButtons,
    IonButton,
    draggable,
  },
  setup() {
    const router = useRouter();

    const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;

    const loading = ref(false);

    const showLiberi = ref(true);
    const showAssegnati = ref(false);

    /**
     * ! Open sidebar menu
     */
    const openMenu = () => {
      menuController.open("app-menu");
    };

    //Leads
    const leads = ref([]);
    const kanban = ref([]);
    /**
     * ! Load all leads
     */
    async function loadLeads() {
      loading.value = true;
      kanban.value = [];
      try {
        const res = await apiLeads.getLeads(userID);
        res.data.forEach((column) => {
          kanban.value.push(column);
        });
        //console.log(kanban.value);
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta dei leads", "toast_danger");
      } finally {
        loading.value = false;
      }
    }

    /**
     * ! Change lead column on drag & drop
     */
    async function changeColumn(event, col, leads) {
      if (event.added) {
        const lead = event.added.element;
        const newColumnId = col.columnId;

        const data = {
          column_id: newColumnId,
          lead_id: lead.id,
        };

        try {
          const res = await apiLeads.updateLeadColumn(data);
          if (res.data.status === 0) {
            openToast(res.data.txt, "toast_danger");
          }
        } catch (error) {
          console.error(error);
          openToast("Errore durante la modifica del lead", "toast_danger");
        }
      }
    }

    /**
     * ! Open new lead page
     */
    async function openNuovoLead(columnId) {
      const modal = await modalController.create({
        component: ModalNuovoLead,
        componentProps: {
          data: columnId,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          //richieste.value = [detail.data, ...richieste.value];
          //Inserimento lead nella colonna corretta
          const createdLead = detail.data;
          const columnToUpdate = kanban.value.find((column) => column.columnId == createdLead.column_id);
          if (columnToUpdate) {
            columnToUpdate.leads.push(createdLead);
          }
          openToast("Lead inserito correttamente", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     * ! Open lead detail
     */
    async function openDetail(leadId) {
      const modal = await modalController.create({
        component: LeadDetail,
        componentProps: {
          data: leadId,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          //richieste.value = [detail.data, ...richieste.value];
          console.log(detail);
          openToast("Lead modificato correttamente", "toast_success");
        }
      });
      return modal.present();
    }

    /**
     * ! Limit customers to 35 charactes
     */
    function setShortCustomer(customer) {
      if (!customer) {
        return "-";
      } else {
        const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
        return trimmedString;
      }
    }

    /**
     * ! Set corrent text color class for recall date
     */
    const isExpired = computed(() => {
      return (lead) => {
        let className = "";
        const leadDate = moment(lead.recall_date);
        const todayDate = moment();

        if (leadDate.isBefore(todayDate)) {
          className = "expired_date";
        }
        return className;
      };
    });

    onMounted(() => {
      //GET LEADS DATA
      loadLeads();
    });

    return {
      loading,
      dateFormat,
      refresh,
      //Nuovi campi
      openMenu,
      menu,
      showLiberi,
      showAssegnati,

      //LEADS
      leads,
      kanban,
      changeColumn,
      openNuovoLead,
      openDetail,
      loadLeads,
      isExpired,
      setShortCustomer,
      addCircle,
    };
  },
};
