<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title color="white">Nuovo lead</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <!-- Customer list, triggered with click on select client in form -->
        <Transition name="fade-transition">
          <div v-show="showCustomer" class="customer_modal">
            <div class="customers_container">
              <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
              <div class="customers_list">
                <div
                  v-for="customer in searchedCustomers"
                  :key="customer.customers_id"
                  @click="setSelectedCustomer(customer)"
                  class="customer"
                  :class="setActiveCustomer(customer, selectedCustomer)"
                >
                  {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                </div>
              </div>
            </div>
            <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
          </div>
        </Transition>

        <form @submit.prevent="creaLead()">
          <ion-list class="fields">
            <!-- Cliente -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title customer_selection" position="floating">
                  <span>
                    Cliente
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </span>
                  <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                </div>
                <div class="value">
                  <div v-if="selectedCustomer">
                    {{
                      selectedCustomer.customers_company
                        ? selectedCustomer.customers_company
                        : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                    }}
                  </div>
                  <div v-else>Nessun cliente selezionato</div>
                </div>
              </div>
            </ion-item>

            <!-- Titolo -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Titolo
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-input type="text" v-model="lead.titolo" placeholder="Richiesta appuntamento"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Email -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Email
                </div>
                <div class="value">
                  <ion-input type="email" v-model="lead.email" placeholder="mariorossi@gmail.com"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Telefono -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Telefono
                </div>
                <div class="value">
                  <ion-input type="tel" v-model="lead.telefono" placeholder=""> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Data inizio -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Richiamare il
                </div>
                <div class="value">
                  <ion-datetime
                    name="date_start"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2021"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="lead.data_richiamo"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Prezzo -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Valore
                </div>
                <div class="value">
                  <ion-input type="number" min="0" step="any" v-model="lead.prezzo" placeholder="Valore del lead"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Descrizione -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Descrizione</div>
                <div class="value">
                  <ion-textarea type="text" rows="3" v-model="lead.descrizione" placeholder="Cliente da richiamare per ulteriori dettagli"> </ion-textarea>
                </div>
              </div>
            </ion-item>

            <div class="actions">
              <button type="submit" class="btn_lead">
                Inserisci lead
              </button>
            </div>
          </ion-list>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonDatetime,
  IonTextarea,
  IonText,
  IonInput,
  IonIcon,
  actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { openToast } from "../services/toast";

import apiClienti from "../services/clienti";
import apiSpese from "../services/spese";
import apiUsers from "../services/users";
import apiLeads from "../services/leads";

export default defineComponent({
  name: "ModalNuovoLead",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonIcon,
  },
  setup(props, context) {
    //console.log(props.data);

    const router = useRouter();

    const recall_date = new Date();

    const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id; //set userID in user field
    const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

    const lead = reactive({
      cliente: null,
      titolo: "",
      email: "",
      telefono: "",
      data_richiamo: "",
      prezzo: 0,
      descrizione: "",
    });

    const successResponse = ref(false);

    const showCustomer = ref(false);
    function openCustomerSelection() {
      showCustomer.value = true;
    }

    function closeCustomerSelection() {
      showCustomer.value = false;
    }

    /**
     * Load customer
     */
    const customers = ref([]);
    async function loadCustomer() {
      try {
        const res = await apiClienti.getCustomersLead();
        if (res.status === 0) {
          customers.value = res.data;
        } else {
          openToast("Errore durante la richiesta dei clienti", "toast_danger");
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta dei clienti", "toast_danger");
      } finally {
        //console.log(customers.value);
      }
    }

    const searchQuery = ref("");
    const searchedCustomers = computed(() => {
      const term = searchQuery.value.replace(/ /g, "");

      return customers.value.filter((cliente) => {
        //company, nome/cognome, province
        if (cliente.customers_company) {
          return (
            cliente.customers_company
              .toLowerCase()
              .replace(/ /g, "")
              .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
          );
        } else {
          if (cliente.customers_name && cliente.customers_last_name) {
            return (
              cliente.customers_name
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
              cliente.customers_last_name
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
              (cliente.customers_last_name + cliente.customers_name)
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
              (cliente.customers_name + cliente.customers_last_name)
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
            );
          } else if (cliente.customers_name && !cliente.customers_last_name) {
            return (
              cliente.customers_name
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
            );
          } else if (!cliente.customers_name && cliente.customers_last_name) {
            return (
              cliente.customers_last_name
                .toLowerCase()
                .replace(/ /g, "")
                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
            );
          }
        }
      });
    });

    const selectedCustomer = ref(null);
    function setSelectedCustomer(customer) {
      //console.log(customer);
      if (customer) {
        searchQuery.value = "";
        selectedCustomer.value = customer;
        showCustomer.value = false;
        //imposta email e telefono con quelli del cliente selezionato
        lead.email = customer.customers_email;
        lead.telefono = customer.customers_phone;
        //imposta il cliente su lead
        lead.cliente = customer.customers_id;
      }
    }

    const setActiveCustomer = computed(() => {
      return (customer, selectedCustomer) => {
        let className = "";

        if (selectedCustomer) {
          if (customer.customers_id === selectedCustomer.customers_id) {
            className = "active_customer";
          }
        }
        return className;
      };
    });

    const users = ref([]);
    async function loadUsers() {
      try {
        const res = await apiUsers.getUsers();
        users.value = res;
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta degli utenti", "toast_danger");
      }
    }

    /**
     * ! Close modal after form data submit
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * ! Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * ! Create new spesa
     */
    async function creaLead() {
      let dataLead = "";
      if (lead.data_richiamo) {
        dataLead = moment(lead.data_richiamo).format("YYYY-MM-DD");
      } else {
        dataLead = "";
      }

      //Cliente obbligatorio
      if (!lead.cliente) {
        openToast("Il cliente è obbligatorio", "toast_danger");
        return;
      }
      //Titolo obbligatorio
      if (!lead.titolo) {
        openToast("Il titolo è obbligatorio", "toast_danger");
        return;
      }
      //Prezzo maggiore di zero
      if (lead.prezzo && lead.prezzo < 0) {
        openToast("Il valore del lead non può essere negativo", "toast_danger");
        return;
      }

      const data = new FormData();
      data.append("leads_member_id", userID); //logged user
      data.append("leads_status", props.data);
      data.append("leads_rating", "1");
      data.append("leads_customer_id", lead.cliente); //logged user
      data.append("leads_title", lead.titolo); //logged user
      data.append("leads_email", lead.email); //logged user
      data.append("leads_phone", lead.telefono); //logged user
      data.append("leads_recall_date", dataLead);
      data.append("leads_price", lead.prezzo); //logged user
      data.append("leads_description", lead.descrizione); //logged user

      const newLead = {
        leads_member_id: userID,
        leads_status: props.data,
        leads_rating: "1",
        leads_customer_id: lead.cliente,
        leads_title: lead.titolo,
        leads_email: lead.email,
        leads_phone: lead.telefono,
        leads_recall_date: moment(lead.data_richiamo).format("YYYY-MM-DD"),
        leads_price: lead.prezzo,
        leads_description: lead.descrizione,
      };

      apiLeads
        .creaLead(newLead)
        .then((response) => {
          console.log(response);
          if (response.data.status == 0) {
            openToast(response.data.txt, "toast_danger");
          } else {
            const res = response.data.data;
            console.log(res);
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          }
        })
        .catch((error) => {
          //console.error(error);
          openToast("Errore durante la creazione del lead", "toast_danger");
        });

      /*       apiLeads
        .saveLead(data)
        .then((response) => {
          console.log(response);
          if (response.data.status == 8) {
            openToast(response.data.message, "toast_danger");
          } else {
            const res = response.data.data[0];
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          }
        })
        .catch((error) => {
          //console.error(error);
          openToast("Errore durante la creazione del lead", "toast_danger");
        })
        .finally(() => {
          console.log("fine creazione lead");
        }); */
    }

    onMounted(() => {
      loadCustomer();
      loadUsers();
    });

    return {
      arrowBackOutline,
      lead,
      creaLead,
      closeModal,
      customers,
      showCustomer,
      openCustomerSelection,
      closeCustomerSelection,
      searchQuery,
      searchedCustomers,
      setSelectedCustomer,
      selectedCustomer,
      setActiveCustomer,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.example {
  border: 1px solid black;
}

.alert-wrapper {
  width: 90% !important;
}

/* New rules */
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.fields {
  width: 100%;
  border-radius: 4px;
  /*padding: 10px;*/
  padding: 16px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.field_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #6b7280;
}
.field_title.customer_selection {
  display: flex;
  justify-content: space-between;
}

.field_title.customer_selection .trigger {
  font-size: 10px;
  padding: 4px 8px;
  background-color: #086fa3;
  color: #ffffff;
  border-radius: 4px;
  text-transform: capitalize;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --highlight-height: 0;
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  margin-bottom: 4px;
}
ion-datetime,
ion-select {
  max-width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  --padding-start: 8px;
}

ion-input,
ion-textarea {
  --placeholder-color: #6b7280;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-top: 0;
}

/* .btn_lead {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
} */
.btn_lead {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  margin-bottom: 8px;
  text-align: center;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  background-color: rgb(22 163 74);
  color: #ffffff;
}

/* .btn_foto {
  --background: #0284c7;
  --background-activated: #0369a1;
  --background-hover: #0369a1;
  --background-focused: #0369a1;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 16px;
} */
.btn_foto {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  background-color: #0a90d4;
  color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  transition: all 0.15s ease-in;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-end: 0;
}

/* Image rules */
.img_photo {
  position: relative;
  max-height: 300px;
}
.img_action {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc2626;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
ion-button {
  --color: #ffffff;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
  transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
  opacity: 0;
}
.customer_modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  padding: 24px;
  align-items: center;
}
.close_modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #ffffff;
  background-color: #086fa3;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 16px;
}
.search_customers {
  width: 100%;
  border: 1px solid #c3c8cf;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 16px;
}
.search_customers::placeholder {
  color: #6b7280;
}
.search_customers:focus-visible {
  /* outline: 1px solid #509843;*/
  outline: 1px solid #334155;
}
.customers_container {
  width: 100%;
  height: 80%;
  background-color: #ffffff;
  border-radius: 4px;
  color: #000000;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.customers_list {
  overflow-y: scroll;
}

.customer {
  padding: 6px;
}
.active_customer {
  background-color: #086fa3;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
}
</style>
